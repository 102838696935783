import { addBaseUrlToUrls, commonEnvironment } from "./environment.common";

export const environment = {
	...commonEnvironment,
	production: false,
	baseUrl: 'https://dev-api.kastenman.be',
	cognito: {
		REGION: 'eu-central-1',
		USER_POOL_ID: 'eu-central-1_a3oBSKiLU',
		APP_CLIENT_ID: '1ifseeu2id8c68pspifsdqnsi7',
		IDENTITY_POOL_ID: 'eu-central-1:4f4093b8-aad1-4a61-9898-e2bd21b29266',
	}
};

addBaseUrlToUrls(environment, commonEnvironment);

